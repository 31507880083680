/* eslint-disable no-unused-vars */
import axios from "axios";
import { GetAllBrochures } from "proxyUrl";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function VlBrochures() {
  const [vlBrochuresData, setVlBrochuresData] = useState("");

  const fetchBrochures = async () => {
    try {
      const response = await axios.get(GetAllBrochures);
      setVlBrochuresData(response.data);
    } catch (error) {
      console.error("Error fetching brochures:", error);
    }
  };

  useEffect(() => {
    fetchBrochures();
  }, []);

  return (
    <>
      <div className=" add-booking-header  relative  mx-4 mt-6 flex justify-between rounded-2xl bg-primaryBlue p-3 shadow-lg dark:bg-navy-800">
        <div className="text-xl font-bold text-white  dark:text-white">
          All Brochures
        </div>
      </div>
      <div className="relative mt-7 rounded-2xl grid w-full grid-cols-1 gap-5 bg-white dark:bg-navy-800 md:grid-cols-1">
        <table className="">
          <thead>
            <th>Name</th>
            <th>File</th>
            <th>Actions</th>
          </thead>
          <tbody className="w-full text-center">
            {vlBrochuresData[0] ? (
              <>
                {vlBrochuresData.map((brochure, index) => (
                  <tr key={index}>
                    <td className="whitespace-no-wrap w-[40%] border-b border-gray-200 px-6 py-4">
                      {brochure.name}
                    </td>
                    <td className="whitespace-no-wrap w-[40%] border-b border-gray-200 px-6 py-4">
                      <a
                        href={brochure.file_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        download=""
                      >
                        {brochure.file_url}
                      </a>
                    </td>
                    <td className="whitespace-no-wrap w-[20%] border-b border-gray-200 px-6 py-4">
                      <Link
                        to={`edit/${brochure.id}`}
                        className="bg-primaryBlue px-5 py-2 text-white dark:bg-navy-800"
                      >
                        Edit
                      </Link>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
