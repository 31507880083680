import { configureStore, combineReducers } from '@reduxjs/toolkit';
import themeReducer from './themeSlice';
import loggedUserSlice from './loggedUserSlice';
import userRoutesSlice from './userRoutesSlice';
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist';



const persistConfig = {
    key: 'root',
    storage,
}
const rootReducer = combineReducers({
    theme: themeReducer,
    user: loggedUserSlice,
    routes: userRoutesSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
    reducer: persistedReducer
});

export const persistor = persistStore(store);