import { useState} from "react";
// import InputField from "components/fields/InputField";
// import Checkbox from "components/checkbox";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginRoute } from "../../proxyUrl";
import axios from "axios";
import { useDispatch } from "react-redux";
import {add} from "store/loggedUserSlice";
export default function SignIn() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  // const [userRole, SetUserRole] = useState('');
  const dispatch = useDispatch();
  const [emailPlace, setEmailPlace] = useState({
    name:'Your email hare...',
    color:''
  });
  const [passPlace, setPassPlace] = useState({
    name:'Your password hare...',
    color:''
  });
  const handleEmail = (e) =>{
    setEmail(e.target.value);
    // validate email and set validEmail state accordingly
    setValidEmail(/^\S+@\S+\.\S+$/.test(e.target.value));
  }
  const adminLogin = async (e) => {
    e.preventDefault();

    if (email  === "" && password === "") {
      setEmailPlace({
        name:"Please Enter Your Email*",
        color: "placeholder-red-800"
      });
      setPassPlace({
        name:"Please Enter Your Password*",
        color: "placeholder-red-800"
      });
    }
    else if (email === "") {
      setEmailPlace({
        name:"Please Enter Your Email*",
        color: "placeholder-red-800"
      })
    }
    else if (!validEmail) {
      return;
    }
    else if (password === "") {
      setPassPlace({
        name:"Please Enter Your Password*",
        color: "placeholder-red-800"
      })
    }
    else{
      await axios
      .post(
        loginRoute,
        {
          email,
          password,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
        //  dispatch(add(res))
        dispatch(add(res.data.user));
        
          if (res.data.user.userRole) {

          const navigation = () =>{
            navigate(`/${'admin'}/dashboard`);
            window.location.reload();
          }
            setTimeout(navigation, 2000);
            toast.success("Logged in Successfully!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          else{
            toast.error("something went wrong!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {
          toast.error("Something Went Wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        return;
      })
      .catch((error) => {
        console.error(error.response.status);
        if (error.response.status === 400) {
          console.log("Invalid credencial");
          toast.error("Invalid credencial", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        // display error message or redirect to an error page
      });
    }
  };
  // THESE FUNCTION WILL HANDLE THE ONFOCUS OF THE EMAIL AND PASSWORD FIELS...{
  function handleFocusEmail() {
    setEmailPlace({
      name:"@gmail.com...",
      color: ""
    });
  }
  function handleFocusPassword() {
    setPassPlace({
      name:"password...",
      color: ""
    })
  }
  
// THESE FUNCTION WILL HANDLE THE ONFOCUS OF THE EMAIL AND PASSWORD FIELS...}
  const variant = "auth";
  return (
    <>
     
      <div className="bg-black z-2 absolute inset-0 h-[100vh] w-[100%] sign-in-wrapper " />

      <div className="relative mt-[150px]  mb-16 flex h-full w-full flex-col  items-center justify-center gap-6  md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center ">
        {/* Sign in section */}

        <div className="mt-3 w-full max-w-full flex-col items-center rounded-lg p-4    xl:max-w-[420px]">
          <div className="z-5 relative mx-4 flex h-[100px] items-center justify-center rounded-2xl bg-primaryBlue  shadow-md">
            <img src="/vlogo.png" alt="" width={130} />
            {/* <h4 className="mb-2.5 text-center text-4xl font-bold text-blue-500 dark:text-white">
              Admin Sign In
            </h4> */}
          </div>

          {/* Email */}
          <div className="mt-[-80px] rounded-2xl bg-white p-7 pt-[100px] shadow-xl">
            <label
              htmlFor="email"
              className={`text-sm text-navy-700 dark:text-white ${
                variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
              }`}
            >
              Email*
            </label>
            <input
              className={` ${emailPlace.color } mb-3 mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
              type="Email"
              name="email"
              id="email"
              required
              onFocus={handleFocusEmail}
              placeholder={emailPlace.name}
              onChange={handleEmail}
            />
           {!validEmail && (
        <p className=" emailvalid  text-[14px] mt-[-14px] ml-1" style={{ color: 'red' }}>Please enter a valid email</p>
      )}
            {/* Email */}

            {/* Password */}
            <label
              htmlFor="password"
              className={`text-sm text-navy-700 dark:text-white ${
                variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
              }`}
            >
              Password*
            </label>
            <input
              className={` ${passPlace.color} mb-3 mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none`}
              type="password"
              name="password"
              id="password"
              onFocus={handleFocusPassword}
              placeholder={passPlace.name}
              onChange={(e) => setPassword(e.target.value)}
            />

            {/* Checkbox */}
            {/* <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a>
        </div> */}
            <Link to="/admin/dashboard">
              <button
                onClick={adminLogin}
                className="linear mt-2 w-full rounded-xl  bg-primaryBlue py-[12px] text-base font-medium text-white transition duration-200 hover:primaryBlue/20 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Sign In
              </button>
            </Link>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
