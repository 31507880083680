import React, { useState, useEffect } from "react";
import "./BlogsForm.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addBlogs,
  UploadBlogBodyImage,
  BlogCategoryDrop,
  UploadFeatureImageUrl,
} from "proxyUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BsFillImageFill } from "react-icons/bs";

const BlogsForm = () => {
  const [content, setContent] = useState();
  const [image, setImage] = useState({});
  const [submiteed, setSubmiteed] = useState(false);
  const [status, setStatus] = useState("1");
  const [preview, setPreview] = useState(null);
  const [categoryDataApi, setCategoryData] = useState("");
  const [catDropData, setCatDropData] = useState("");
  console.log(catDropData,76543);
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCatDropData(event.target.value);
  };
  const handleImage = (e) => {
    setImage(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };
  const getCatData = async () => {
    await axios.get(BlogCategoryDrop).then((res) => {
      console.log(res.data, 658755);
      setCategoryData(res.data);
    });
  };
  const navigate = useNavigate();
  const [user, setUser] = useState({
    title: "",
    metadescription: "",
    slug: "",
    metakeywords: "",
    metatitle: "",
    alttext: "",
    canonical: "",
    shortDescription: "",
  });
  let name, value;
  const handleInput = (e) => {
    // console.log(e);
   
    name = e.target.name;
    value = e.target.value;
  
    setUser({ ...user, [name]: value });
  };

  //Setting the title as slug bu default

    useEffect(() =>{
      setUser({...user, slug: user.title});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ user.title])
    
  const sendData = async (e) => {
    e.preventDefault();
    setSubmiteed(true);
    if (
      !user.alttext ||
      !user.canonical ||
      !user.metakeywords ||
      !user.metadescription ||
      !user.slug ||
      !user.title ||
      !image
    ) {
      toast.error("please  the data!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    //creating form data
    const formData = new FormData();
    formData.append("image", image);

    await axios
      .post(UploadFeatureImageUrl, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          axios
            .post(
              addBlogs,
              {
                title: user.title,
                category: catDropData,
                slug: user.slug,
                content: content,
                metakeywords: user.metakeywords,
                metadescription: user.metadescription,
                metatitle: user.metatitle,
                alttext: user.alttext,
                canonical: user.canonical,
                shortDescription: user.shortDescription,
                status: status,
                image: res.data.imageUrl,
              },
              {
                withCredentials: true,
              }
            )
            .then((res) => {
              console.log(res.status);
              if (res.status === 200) {
                console.log("Form submiteed succsesfully");
                toast.success("Form submiteed succsesfully!", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                const delayfrfresh = () => {
                  navigate("/admin/blogs");
                };
                // eslint-disable-next-line
                const delayRef = setTimeout(delayfrfresh, 3100);
              }
            })
            .catch((err) => {
              console.log(err.response.status);
              if (err.response.status === 400) {
                console.log("Please fill all data");
                toast.error("Please fill all data!", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                const delay = () => {
                  setSubmiteed(false);
                };
                // eslint-disable-next-line
                const myTimeout = setTimeout(delay, 3000);
              } else {
                console.log("something went wrong");
                toast.error("something went wrong!", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                const delay = () => {
                  setSubmiteed(false);
                };
                // eslint-disable-next-line
                const myTimeout = setTimeout(delay, 3000);
              }
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const tork = false;
  const [userPlace, setUserPlace] = useState({
    title: "Enter Blog Title",
    metadescription: "Enter Blog Meta Descrioption ",
    slug: "Enter Blog  slug ",
    content: "Enter Blog Content",
    metakeywords: "Enter Blog Metakeywords",
    metatitle: "Enter Blog Metatitle",
    alttext: "Enter Blog Alttext",
    canonical: "Enter Blog Canonical",
    Description: "Type Hare....",
  });

  if (tork === true) {
    setUserPlace(null);
  }

  useEffect(() => {
    getCatData();
  }, [image]);
  return (
    <>
      <div className="mt-7  mb-2 rounded-lg bg-white shadow-xl dark:bg-navy-800 ">
        <div className="mx-auto w-[90%]  ">
          <form action="" method="POST" className="">
            <div className="">
              <div className="field w-[100%] ">
                <div className="input  relative">
                  <label htmlFor="title"> Title</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.title}`}
                    maxLength={80}
                    name="title"
                    value={user.title}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="slug" className="">
                    Slug
                  </label>
                  <input
                    type="text"
                    placeholder={`${userPlace.slug}`}
                    name="slug"
                    value={user.slug}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="field">
                <div className="input relative">
                  <label htmlFor="metakeywords"> Metakeywords</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.metakeywords}`}
                    name="metakeywords"
                    value={user.metakeywords}
                    onChange={handleInput}
                  />
                </div>
                <div className="input  relative ">
                  <label htmlFor="Metatitle"> Metatitle</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.metatitle}`}
                    name="metatitle"
                    value={user.metatitle}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="field">
                <div className="input relative ">
                  <label htmlFor="category"> Meta Description</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.metadescription}`}
                    name="metadescription"
                    value={user.metadescription}
                    onChange={handleInput}
                  />
                </div>

                <div className="input  relative ">
                  <label htmlFor="canonical">Canonical</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.canonical}`}
                    name="canonical"
                    value={user.canonical}
                    onChange={handleInput}
                  />
                </div>
              </div>

              <div className="field">
                <div className="input relative">
                  <label htmlFor="alttext"> Alttext</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.alttext}`}
                    name="alttext"
                    maxLength={80}
                    value={user.alttext}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="shortDescription">Short Description</label>
                  <input
                    className="h-[53px] overflow-hidden !rounded-md   "
                    id="shortDescription"
                    type="text"
                    value={user.shortDescription}
                    onChange={handleInput}
                    name="shortDescription"
                  />
                </div>
              </div>
              <div className="field">
                <div className="input relative">
                  <label htmlFor="status-select">Status:</label>
                  <select
                    id="status-select"
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <option value="1">Active</option>
                    <option value="0">Disabled</option>
                  </select>
                </div>
                <div className="input relative">
                  <label htmlFor="status-select">Category:</label>
                  <select
                    id="status-select"
                    value={catDropData}
                    onChange={handleCategoryChange}
                    className=" capitalize"
                  >
                    {categoryDataApi
                      ? categoryDataApi.map((data, index) => {
                          return (
                            <option
                              className=" capitalize"
                              key={index}
                              value={data.value}
                            >
                              {data.name}
                            </option>
                          );
                        })
                      : ""}
                  </select>
                </div>
              </div>
              <div className="field my-6">
                <div className="input relative mx-2 mr-7 flex items-center justify-center rounded-lg border-2 border-blueSecondary   ">
                  <div className=" h-[200px] w-[400px] rounded-lg ">
                    {preview ? (
                      <img
                        className="h-full w-full rounded-md object-cover "
                        src={preview}
                        alt=""
                      />
                    ) : (
                      <>
                        <div className=" flex h-full w-full items-center justify-center text-8xl text-primaryBlue ">
                          <BsFillImageFill />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="input relative">
                  <label htmlFor="alttext">Uploade File</label>
                  <input
                    type="file"
                    name="file"
                    // value={user.alttext}
                    onChange={handleImage}
                  />
                </div>
              </div>
              <div className="relative mx-2">
                <h2 className="mb-1"> Content</h2>
                <div className="hidden">
                  <CKEditor
                    value={content}
                    editor={ClassicEditor}
                    placeholder={""}
                    data=""
                    onReady={(editor) => {}}
                    config={{
                      // plugins: [ Essentials ],
                      ckfinder: {
                        // The URL that the images are uploaded to.
                        // uploadUrl: "/upload",

                        // Enable the XMLHttpRequest.withCredentials property.
                        withCredentials: true,

                        // Headers sent along with the XMLHttpRequest to the upload server.
                        headers: {
                          "X-CSRF-TOKEN": "CSFR-Token",
                          Authorization: "Bearer <JSON Web Token>",
                        },
                        uploadUrl: "",
                      },
                    }}
                    // onInit={editor => {
                    //   editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                    //     return new MyUploadAdapter(loader);
                    //   };
                    // }}
                    onChange={(handleInput, editor) => {
                      const data = editor.getData();
                      setContent(data);
                    }}
                  />
                </div>
                <CKEditor
                  value={content}
                  editor={ClassicEditor}
                  onInit={(editor) => {
                    //// Here the editor is ready to be used
                  }}
                  onChange={(handleInput, editor) => {
                    // console.log(handleInput,8757847878787878787);
                    const data = editor.getData();
                    setContent(data);
                  }}
                  config={{
                    // plugins: [ Essentials ],
                    ckfinder: {
                      // The URL that the images are uploaded to.
                      uploadUrl: `${UploadBlogBodyImage}`,

                      // Enable the XMLHttpRequest.withCredentials property.
                      withCredentials: true,

                      // Headers sent along with the XMLHttpRequest to the upload server.
                    },
                  }}
                />
              </div>
              {submiteed ? (
                <div className="field cursor-pointer ">
                  <div className=" my-5 rounded-md bg-gray-300 py-3 px-12 text-white">
                    <button disabled>Loading....</button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="field cursor-pointer ">
                    {submiteed ? (
                      <>
                        <div className=" my-5 rounded-md bg-primaryBlue/30 py-3 px-12 text-white">
                          <button disabled>Loading...</button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          onClick={sendData}
                          className=" my-5 rounded-md bg-primaryBlue py-3 px-12 text-white"
                        >
                          <button>Submit</button>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default BlogsForm;
