// export const host = "http://localhost:4002";
export const host = "https://api.valueloads.com";
export const loginRoute = host+'/signin';
export const logOut = host+'/admin/sign-out';
export const userAuthentication = host+'/user/authentication';
// export const getUserList = host+'/admin/user/list';
export const addResister = host+'/register/add';
export const getAllUserList = host+'/user/list';
export const getOneUserById = host+'/getoneuserbyid';
export const UploadUserImage = host+'/upload_user_image';
export const userLogout = host+'/admin/sign-out'; 
export const formApprove = host+'/approve';
export const GetBookingDataByUserId = host+'/get/registerdata';
export const GetOneBookingDataById = host+'/register/get';
export const EditBookingDataById = host+'/register/edit';
export const DeleteBookingDataById = host+'/register/delete';
export const GetUserRoles = host+'/role';
export const getpermitions = host+'/getpermissions';
export const addUser = host+'/useradd';
export const getManagerData= host+'/managerData';
export const getTlData= host+'/teamleaderData';
export const getUserDataByToken = host+'/getuserinfo';

export const addBlogs = host+'/creedblog';
export const getAllBlog = host+'/blog/fetch';
export const BlogCategoryDrop = host+'/api/blogs/categoriedrop';
export const GetBlogsDataById = host+'/blog/getSingleAdminBlog/';
export const UpdateBlogData = host+'/blog/blog_update';
export const DeleteBlogData = host+'/blog/blog_delete';
export const UploadBlogBodyImage= host+'/bodyimageupload';
export const UploadFeatureImageUrl= host+'/upload_feature_image';

export const GetAllBrochures = host + '/vlBrochures';
export const UpdateBrochure = host + '/vlBrochures/edit';
export const GetSingleBrochure = host + '/singleVlBrochures/';
export const UploadPdf = host + '/uploadPDF';

// acefreightExperts
export const GetAllAceBrochures = host + '/acefright-Brochures';
export const UpdateAceBrochure = host + '/acefright-Brochures/edit';
export const GetSingleAceBrochure = host + '/single-acefright-Brochures/';