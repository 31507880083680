import React, { useEffect, useState } from "react";
import axios from "axios";
import { DeleteBookingDataById, GetOneBookingDataById } from "proxyUrl";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function UserDelete() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [bookingContentFromApi, setBookingContentFromApi] = useState();
  const [loading, setLoading] = useState(false);

  const CurrentUserId = useSelector(state => state.user.user);

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    id: "",
    status: "",
    metakeywords: "",
    metatitle: "",
    alttext: "",
    canonical: "",
    shortDescription: "",
  });
//   console.log(bookingContentFromApi);
  const GettingBookigContentFromApi = () => {
    axios
      .get(`${GetOneBookingDataById}/${id}`, {
        withCredentials: true,
      })
      .then((data) => {
        const booking = data.data;
        setBookingContentFromApi(booking.booking);
      });
  };

  const handleDelete = async () => {
    setLoading(true);

    try {
      await axios.put(`${DeleteBookingDataById}/${id}`).then(res =>{
       if (res.status === 204) {
        toast.success("Booking Deleted succsesfully!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
       }
       else {
        toast.error("something happened wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
       }

       const delayfrfresh = () => {
        navigate(`/${CurrentUserId.userRole.name}/bookings`)
      };
      // eslint-disable-next-line
      const delayRef = setTimeout(delayfrfresh, 3100);

      }).catch(err =>{
        if(err.response.status === 404) {
            toast.error("Blog Not Found in Record!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
           }
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    GettingBookigContentFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUser({
      ...user,
      firstName: bookingContentFromApi ? bookingContentFromApi.firstName : "",
      lastName: bookingContentFromApi ? bookingContentFromApi.lastName : "",
      id: bookingContentFromApi ? bookingContentFromApi.id : "",

      status: bookingContentFromApi
        ? bookingContentFromApi.status
        : "0",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingContentFromApi]);


  return (
    <div className="  my-10 flex h-[78vh]  w-full flex-col items-center justify-center     gap-5 ">
     
     <div className="max-w-sm mx-auto bg-white p-4 rounded-lg ">
      <h2 className="text-2xl font-bold mb-4">User Information</h2>
      <p>
        <span className="font-bold">Booking ID:</span> {user.id}
      </p>
      <p>
        <span className="font-bold">Customer Name:</span> {user.firstName} {user.lastName}
      </p>
      <p>
      </p>
      <p>
        <span className="font-bold">Status:</span> {
            user.status === '0'? 
                <>pending</>
             : <> Approved</>
        }
      </p>
      {/* Display other booking information */}
    </div>
      <button
        className=" rounded-full text-xl hover:bg-red-500 duration-300 bg-red-400 px-10 py-5 text-white shadow-md "
        onClick={handleDelete}
        disabled={loading}
      >

       Delete
      </button>
      <ToastContainer/>
    </div>
  );
}
export default UserDelete;
