
import Card from "components/card";
import { Link } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import {FaTrash} from "react-icons/fa";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdOutlineError } from "react-icons/md";
import { useMemo } from "react";
import Progress from "components/progress";
// import { button } from "@material-tailwind/react";
const ComplexTable = (props) => {
  const { columnsData, deleteRoute, editRoute,  tableData, columnLenghth } = props;
  console.log(deleteRoute,5687587685768578);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;

  return (
    <Card extra={"w-full h-full px-6 pb-6 py-5 sm:overflow-x-auto "}>
      {/* <div className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Booking Status
        </div>
        <CardMenu />
      </div> */}

      <div className=" overflow-x-scroll xl:overflow-x-hidden  tableall-costom">
        <table {...getTableProps()} className="w-full">
          <thead className="">
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="border-b border-gray-200  pb-[10px] text-start dark:!border-navy-700"
                  >
                    <p className="text-xs tracking-wide text-gray-600">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="  " >
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index} className=" " >
                  {row.cells.map((cell, index) => {
                    let data = "";
                    // console.log(cell.column,8);
                   if (cell.column.Header === "ID") {
                      data = (
                        <p className="  text-sm font-bold text-navy-700 dark:text-white">
                          {columnLenghth}
                        </p>
                      );
                    } 
                    else if (cell.column.Header.toUpperCase() === "TITLE") {
                      data = (
                        cell.value ? (<p  className="   text-sm font-bold text-navy-700 dark:text-white">
                        {cell.value}
                      </p>) : <div className="">-</div>
                      );
                    }
                    else if (cell.column.Header.toUpperCase() === "CATEGORY") {
                      data = (
                        cell.value ? (<p  className="   text-sm font-bold text-navy-700 dark:text-white">
                        {cell.value}
                      </p>) : <div className="">-</div>
                      );
                    }
                    else if (cell.column.Header.toUpperCase() === "SLUG") {
                      data = (
                        cell.value ? (<p  className="   text-sm font-bold text-navy-700 dark:text-white">
                        {cell.value}
                      </p>) : <div className="">-</div>
                      );
                    }
                    else if (cell.column.Header.toUpperCase() === "METAKEYWORDS") {
                      data = (
                        cell.value ? (<p  className="   text-sm font-bold text-navy-700 dark:text-white">
                        {cell.value}
                      </p>) : <div className="">-</div>
                      );
                    }
                    else if (cell.column.Header.toUpperCase() === "IMAGE") {
                      data = (
                        cell.value ? (<p  className="   text-sm font-bold text-navy-700 dark:text-white">
                        {cell.value}
                      </p>) : <div className="">-</div>
                      );
                    }
                    else if (cell.column.Header === "FIRST NAME") {
                      data = (
                        cell.value ? (<p  className="   text-sm font-bold text-navy-700 dark:text-white">
                        {cell.value}
                      </p>) : <div className="">-</div>
                      );
                    }
                    else if (cell.column.Header === "AIRLINE") {
                      data = (
                        cell.value ? (<p  className="   text-sm font-bold text-navy-700 dark:text-white">
                        {cell.value}
                      </p>) : <div className="">-</div>
                      );
                    }
                    else if (cell.column.Header === "PNR") {
                      data = (
                        cell.value ? (<p  className="   text-sm font-bold text-navy-700 dark:text-white">
                        {cell.value}
                      </p>) : <div className="">-</div>
                      );
                    }
                   else if (cell.column.Header === "NAME") {
                      data = (
                        cell.value ? (<p  className="   text-sm font-bold text-navy-700 dark:text-white">
                        {cell.value}
                      </p>) : <div className="">-</div>
                      );
                    } else if (cell.column.Header === "DATE") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "EMAIL") {
                      data = (
                       cell.value ? ( <p className="text-sm font-bold text-navy-700 dark:text-white">
                       {cell.value}
                     </p>) : <div className="">-</div>
                      );
                    } 
                    else if (cell.column.Header === "PHONE") {
                      data = (
                       cell.value ? ( <p className="text-sm font-bold text-navy-700 dark:text-white">
                       {cell.value}
                     </p>) : <div className="">-</div>
                      );
                      }
                      else if (cell.column.Header === "ACTION") {
                      return (
                        <>
                        <div className="gap-3  mt-3 pl-4 flex items-center justify-start">
                        <Link to={`${editRoute}/${cell.value}`}>
                          <button className=" rounded-full p-2 text-sm bg-primaryBlue text-white shadow-md   dark:bg-navy-900">
                            <FiEdit2 />
                          </button>
                        </Link>
                         <Link to={`${deleteRoute}/${cell.value}`}>
                         <button className="p-2 text-sm rounded-full bg-primaryBlue text-white shadow-md dark:text-yellow-300   dark:bg-navy-900">
                           <FaTrash />
                         </button>
                       </Link>
                       </div>
                       </>
                      );
                    } 
                    else if (cell.column.Header === "STATUS") {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className={`rounded-full text-xl`}>
                            {cell.value === "1" ? (
                              // <MdCheckCircle className="text-green-500" />
                          <button className="status-approved-btn shadow-md dark:bg-navy-900 dark:text-green-400">Approved</button>
                            ) : cell.value === "0" ? (
                              <button className="status-pending-btn shadow-md dark:bg-navy-900 dark:text-orange-400 " >Pending</button>
                              // <MdCancel className="text-red-500" />
                            ) : cell.value === "Error" ? (
                              <MdOutlineError className="text-orange-500" />
                            ) : null}
                          </div>
                        </div>  
                      );
                    } else if (cell.column.Header === "PROGRESS") {
                      data = <Progress width="w-[108px]" value={cell.value} />;
                    }
                    return (
                      <td
                        className="pt-[14px] pb-[18px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};
export default ComplexTable;
