import React from "react";
import avatar from "assets/img/avatars/avatar11.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import {host} from 'proxyUrl';

const Banner = (props) => {
  const {userInfo} = props;

  const userImage = userInfo.userInfo.image?`${host}/public/userImage/${userInfo.userInfo.image}`: '/profile.jpg';

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-12 flex h-[127px] w-[127px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
        {
          userInfo? (
            <img className="h-full w-full object-cover rounded-full" src={userImage} alt="" />
          ) :   <img className="h-full w-full rounded-full" src={avatar} alt="" />
        }
        </div>
      </div>

      {/* Name and position */}
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
         {
          userInfo? (
            userInfo.userInfo.name
          ) : "user name"
         }
        </h4>
        <p className="text-base font-normal text-gray-600">
          {
            userInfo? (
              userInfo.userRole.name
            ) : "user"
          }
        </p>
      </div>

      {/* Post followers */}
      <div className="mt-6 mb-3 flex gap-4 md:!gap-14">
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">17</p>
          <p className="text-sm font-normal text-gray-600">Total Bookings</p>
        </div>
        {/* <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            9.7K
          </p>
          <p className="text-sm font-normal text-gray-600">Followers</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            434
          </p>
          <p className="text-sm font-normal text-gray-600">Following</p>
        </div> */}
      </div>
    </Card>
  );
};

export default Banner;
