import React, { useState, useEffect } from "react";
import "./BlogsForm.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UploadBlogBodyImage, UploadFeatureImageUrl } from "proxyUrl";
import { UpdateBlogData, GetBlogsDataById } from "proxyUrl";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const BlogsFormEdit = () => {
  const { id } = useParams();

  const [letestImage, setLetestImage] = useState(null)
  const [blogContentFromApi, setBlogContentFromApi] = useState();
  const [preview, setPreview] = useState(null);
  const [content, setContent] = useState();
  const [oldImage, setOldImage] = useState();
  const [image, setImage] = useState();
  const [submiteed, setSubmiteed] = useState(false);
  const [status, setStatus] = useState();
  const [categories,setCategories] = useState("");
  const [category,setCategory] = useState("");
  
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  const handleImage = (e) => {
    const selectedFile = e.target.files[0];
    setImage(selectedFile);
    setPreview(URL.createObjectURL(selectedFile));
  };
  const navigate = useNavigate();
  const [user, setUser] = useState({
    title: "",
    metadescription: "",
    slug: "",
    metakeywords: "",
    metatitle: "",
    alttext: "",
    canonical: "",
    shortDescription: "",
  });

  const GettingBlogblogContentFromApi = () => {
    axios
      .get(`${GetBlogsDataById}${id}`, {
        withCredentials: true,
      })
      .then((data) => {
        const blog = data.data;
        console.log(blog,56789);
        setBlogContentFromApi(blog);
      });
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  useEffect(() => {
    GettingBlogblogContentFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setUser({
      ...user,
      title: blogContentFromApi ? blogContentFromApi.title : "",
      category: blogContentFromApi ? blogContentFromApi.category : "",
      slug: blogContentFromApi ? blogContentFromApi.slug : "",
      metakeywords: blogContentFromApi ? blogContentFromApi.metakeywords : "",
      metatitle: blogContentFromApi ? blogContentFromApi.metatitle : "",
      canonical: blogContentFromApi ? blogContentFromApi.canonical : "",
      alttext: blogContentFromApi ? blogContentFromApi.alttext : "",
      metadescription: blogContentFromApi
        ? blogContentFromApi.metadescription
        : "",
      shortDescription: blogContentFromApi
        ? blogContentFromApi.shortDescription
        : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogContentFromApi]);

  useEffect(() => {
    setContent(blogContentFromApi ? blogContentFromApi.content : "");
    setStatus(blogContentFromApi ? blogContentFromApi.status : 1);
    setOldImage(blogContentFromApi ? blogContentFromApi.image : "");
    setLetestImage(blogContentFromApi ? blogContentFromApi.image : "");
    setCategories(blogContentFromApi ? blogContentFromApi.category.categories : "");
    setCategory(blogContentFromApi ? blogContentFromApi.category.value : "");
  }, [blogContentFromApi]);

  let name, value;
  const handleInput = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };
  
  const sendData = async (e) => {
    e.preventDefault();

    const imageArray = []
    //creating form data
   if (image) {
    const formData = new FormData();
    formData.append("image", image);
    await axios
      .post(UploadFeatureImageUrl, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          imageArray.push(res.data.imageUrl);
        }
      })
      .catch((err) => {
        console.log(err);
      });
   }
  await axios
  .put(
    `${UpdateBlogData}/${id}`,
    {
      title: user.title,
      category: category,
      slug: user.slug,
      content: content,
      metakeywords: user.metakeywords,
      metadescription: user.metadescription,
      metatitle: user.metatitle,
      alttext: user.alttext,
      canonical: user.canonical,
      shortDescription: user.shortDescription,
      status: status,
      image: imageArray[0]? imageArray[0] : letestImage,
      oldImage: imageArray[0]? oldImage: '',
    },
    {
      withCredentials: true,
    }
  )
  .then((res) => {
    console.log(res.status);
    if (res.status === 200) {
      console.log("Form submiteed succsesfully");
      toast.success("Form submiteed succsesfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const delayfrfresh = () => {
        navigate("/admin/blogs");
      };
      // eslint-disable-next-line
      const delayRef = setTimeout(delayfrfresh, 3100);
    }
  })
  .catch((err) => {
    console.log(err.response.status);
    if (err.response.status === 400) {
      console.log("Please fill all data");
      toast.error("Please fill all data!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const delay = () => {
        setSubmiteed(false);
      };
      // eslint-disable-next-line
      const myTimeout = setTimeout(delay, 3000);
    } else {
      console.log("something went wrong");
      toast.error("something went wrong!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const delay = () => {
        setSubmiteed(false);
      };
      // eslint-disable-next-line
      const myTimeout = setTimeout(delay, 3000);
    }
  });
 
  };
  const tork = false;
  const [userPlace, setUserPlace] = useState({
    title: "Enter Blog Title",
    metadescription: "Enter Blog Meta Descrioption ",
    slug: "Enter Blog  slug ",
    content: "Enter Blog Content",
    metakeywords: "Enter Blog Metakeywords",
    metatitle: "Enter Blog Metatitle",
    alttext: "Enter Blog Alttext",
    canonical: "Enter Blog Canonical",
    Description: "Type Hare....",
  });

  if (tork === true) {
    setUserPlace(null);
  }

  useEffect(() => {}, [image]);
  return (
    <>
      <div className="mt-7  mb-2 rounded-lg bg-white shadow-xl dark:bg-navy-800 ">
        <div className="mx-auto w-[90%]  ">
          <form action="" method="POST" className="">
            <div className="">
              <div className="field w-[100%] ">
                <div className="input  relative">
                  <label htmlFor="title"> Title</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.title}`}
                    maxLength={80}
                    name="title"
                    value={user.title}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="slug" className="">
                    Slug
                  </label>
                  <input
                    type="text"
                    placeholder={`${userPlace.slug}`}
                    name="slug"
                    value={user.slug}
                    onChange={handleInput}
                  />
                </div>
              </div>

              <div className="field">
                <div className="input relative">
                  <label htmlFor="metakeywords"> Metakeywords</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.metakeywords}`}
                    name="metakeywords"
                    value={user.metakeywords}
                    onChange={handleInput}
                  />
                </div>
                <div className="input  relative ">
                  <label htmlFor="Metatitle"> Metatitle</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.metatitle}`}
                    name="metatitle"
                    value={user.metatitle}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="field">
                <div className="input relative ">
                  <label htmlFor="category"> Meta Description</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.metadescription}`}
                    name="metadescription"
                    value={user.metadescription}
                    onChange={handleInput}
                  />
                </div>

                <div className="input  relative ">
                  <label htmlFor="canonical">Canonical</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.canonical}`}
                    name="canonical"
                    value={user.canonical}
                    onChange={handleInput}
                  />
                </div>
              </div>
              
              <div className="field">
                <div className="input relative">
                  <label htmlFor="alttext"> Alttext</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.alttext}`}
                    name="alttext"
                    maxLength={80}
                    value={user.alttext}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="shortDescription">Short Description</label>
                  <input
                    className="h-[53px] overflow-hidden !rounded-md   "
                    id="shortDescription"
                    type="text"
                    value={user.shortDescription}
                    onChange={handleInput}
                    name="shortDescription"
                  />
                </div>
              </div>
              <div className="field">
                <div className="input relative">
                  <label htmlFor="status-select">Status:</label>
                  <select
                    id="status-select"
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <option value="1">Active</option>
                    <option value="0">Disabled</option>
                  </select>
                </div>
                <div className="input relative">
                  <label htmlFor="alttext"> Category</label>
                  <select
                    id="status-select"
                    value={category}
                    onChange={handleCategoryChange}
                    className=" capitalize"
                  >
                    {categories
                      ? categories.map((data, index) => {
                          return (
                            <option
                              className=" capitalize"
                              key={index}
                              value={data.value}
                            >
                              {data.name}
                            </option>
                          );
                        })
                      : ""}
                  </select>
                </div>
              </div>
              <div className="field my-8 ">
                <div className="input relative mx-2 mr-7 flex items-center justify-center rounded-lg border-2 border-blueSecondary   ">
                  <div className=" h-[200px] w-[400px] rounded-lg   ">
                    {preview ? (
                      <img
                        className="h-full w-full rounded-md object-cover "
                        src={preview}
                        alt=""
                      />
                    ) : (
                      <img
                        className="h-full w-full rounded-md object-cover "
                        src={oldImage}
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div className="input relative">
                  <label htmlFor="alttext">Change Image</label>
                  <input
                    type="file"
                    name="file"
                    // value={user.alttext}
                    onChange={handleImage}
                  />
                </div>
              </div>
              
              <div className="relative mx-2">
                <h2 className="mb-1"> Content</h2>
                <div className="hidden">
                  <CKEditor
                    value={content}
                    editor={ClassicEditor}
                    placeholder={""}
                    data=""
                    onReady={(editor) => {}}
                    config={{
                      // plugins: [ Essentials ],
                      ckfinder: {
                        // The URL that the images are uploaded to.
                        // uploadUrl: "/upload",

                        // Enable the XMLHttpRequest.withCredentials property.
                        withCredentials: true,

                        // Headers sent along with the XMLHttpRequest to the upload server.
                        headers: {
                          "X-CSRF-TOKEN": "CSFR-Token",
                          Authorization: "Bearer <JSON Web Token>",
                        },
                        uploadUrl: "/api/upload-image",
                      },
                    }}
                    // onInit={editor => {
                    //   editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                    //     return new MyUploadAdapter(loader);
                    //   };
                    // }}
                    onChange={(handleInput, editor) => {
                      const data = editor.getData();
                      setContent(data);
                    }}
                  />
                </div>
                <CKEditor
                  value={content}
                  editor={ClassicEditor}
                  data={content}
                  onInit={(editor) => {
                    //// Here the editor is ready to be used
                  }}
                  onChange={(handleInput, editor) => {
                    // console.log(handleInput,8757847878787878787);
                    const data = editor.getData();
                    setContent(data);
                  }}
                  config={{
                    // plugins: [ Essentials ],
                    ckfinder: {
                      // The URL that the images are uploaded to.
                      uploadUrl: `${UploadBlogBodyImage}`,

                      // Enable the XMLHttpRequest.withCredentials property.
                      withCredentials: true,

                      // Headers sent along with the XMLHttpRequest to the upload server.
                    },
                  }}
                />
              </div>
              {submiteed ? (
                <div className="field cursor-pointer ">
                  <div className=" my-5 rounded-md bg-gray-300 py-3 px-12 text-white">
                    <button disabled>Loading....</button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="field cursor-pointer " onClick={sendData}>
                    <div className=" my-5 rounded-md bg-primaryBlue py-3 px-12 text-white">
                      <button>Submit</button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default BlogsFormEdit;
