import React from "react";

// Auth Imports
import SignIn from "views/auth/SignIn";
import { AiOutlineLogout } from 'react-icons/ai';
import SignOut from "views/auth/SignOut";
import Resister from "views/auth/resister";

const routes = [

  {
    name: "Log In",
    layout: "/auth",
    path: "sign-in",
    display: "hidden",
    icon: <AiOutlineLogout className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Log Out",
    layout: "/auth",
    path: "sign-out",
    display: "",
    icon: <AiOutlineLogout className="h-6 w-6" />,
    component: <SignOut />,
  },
  {
    name: "resiter",
    layout: "/auth",
    path: "resiter",
    display: "",
    icon: <AiOutlineLogout className="h-6 w-6" />,
    component: <Resister />,
  },
 
];
export default routes;
